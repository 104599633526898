@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();

  @include app-layout();
  // loading progress bar
  @include app-pace-theme();

  @include nb-overrides();
}

.menu-item {
  text-align: left;
}

// @import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

// quill editor
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@import '~quill/dist/quill.core.css';

.columns nb-layout-column {
  padding: 0 !important;
}

.mat-vertical-content {
  margin-top: 20px;

  .mat-expansion-indicator {
    margin-left: 10px;
  }
}

.nb-layout-column-custom {
  height: max-content;
}

.title-field-box {
  .mat-mdc-form-field-subscript-wrapper,
  .mat-mdc-form-field-bottom-align {
    display: none !important;
  }
}

.mat-expansion-panel-body {
  padding: 13px 24px 16px !important;
}

.manual-value-box {
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0 !important;
  }
}

.custom-toggle {
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0 !important;
  }
}

button {
  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }
}

.slide-container {
  width: 200px;

  .mdc-button {
    display: flex;
    justify-content: start !important;
  }
}

.slide-card-actions {
  .mdc-button {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }
}

.progress-container {
  .mat-mdc-progress-bar {
    --mdc-linear-progress-track-height: 20px;
    --mdc-linear-progress-active-indicator-height: 20px;
    border-radius: 20px;
    // color: #8E9AAF !important;
    // border-color: #8E9AAF !important;
    background-color: #d9d9d9 !important;
  }

  .mdc-linear-progress__buffer-bar {
    background-color: #d9d9d9 !important;
  }

  .mdc-linear-progress__bar-inner {
    border-color: #8e9aaf !important;
    // color: #0f4cbe !important;
    background-color: #d9d9d9 !important;
  }
}
